import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExamsForMe, setExam } from "../store/slices/exam";
import { useNavigate } from "react-router-dom";

const ExamList = () => {
  const [ongoingExams, setOngoingExams] = useState([]);
  const [notStartedExams, setNotStartedExams] = useState([]);
  const [completedExams, setCompletedExams] = useState([]);
  const navigate = useNavigate();

  const convertToMinandSec = (second) => {
    const min = Math.floor(second / 60);
    const sec = second % 60;
    return `${min} min ${sec} sec
    `;
  };

  const redirectToExam = (exam) => {
    dispatch(setExam(exam));
    sessionStorage.setItem("exam_id", exam.id);
    navigate("/instruction");
  };

  const dispatch = useDispatch();
  const { examsForMe, examError } = useSelector((state) => state.exam);

  useEffect(() => {
    dispatch(getExamsForMe());
  }, []);

  useEffect(() => {
    if (examsForMe?.exam_taker?.length > 0) {
      const ongoingExams = examsForMe?.exam_taker.filter(
        (exam) => exam.submitted_at === null && exam.started_at !== null
      );
      const notStartedExams = examsForMe?.exam_taker.filter(
        (exam) => exam.started_at === null
      );
      const completedExams = examsForMe?.exam_taker.filter(
        (exam) => exam.submitted_at !== null
      );
      setOngoingExams(ongoingExams);
      setNotStartedExams(notStartedExams);
      setCompletedExams(completedExams);
    }
  }, [examsForMe]);

  return (
    <>
      <div className="layout py-6 min-h-[88vh] overflow-hidden h-max w-full relative flex flex-col justify-center items-center gap-6 font-primary z-20">
        <div className="flex flex-col gap-10 w-full min-h-[88vh] ">
          <div className="flex flex-col">
            <p className="mb-4 text-xl font-semibold">Exams For You</p>

            {notStartedExams.length > 0 ? (
              <div className="grid gap-6 p-6 grid-cols1 lg:grid-cols-3 min-h-40 h-max bg-primary/5">
                {notStartedExams.map((exam) => {
                  return (
                    <div className="flex flex-col w-full h-full gap-6 p-3 bg-white rounded-lg sm:flex-row sm:items-center sm:justify-between min-h-20">
                      <div className="flex flex-col justify-between w-full h-full gap-4 capitalize">
                        <div>
                          <p className="font-semibold ">{exam?.exam?.name}</p>
                          <p className="text-sm text-neutral-400">
                            {exam?.exam?.category?.name}
                          </p>
                          <p className="text-xs text-neutral-400">
                            {/* {exam?.exam?.duration} min */}
                            {convertToMinandSec(exam?.exam?.duration)}
                          </p>
                        </div>
                        <div className="flex flex-wrap gap-2 text-primary sm:items-center sm:justify-start">
                          {exam?.exam?.category?.sub_categories?.map((item) => {
                            return (
                              <div className="flex items-center gap-2 p-2 rounded-full bg-neutral-100">
                                <p className="text-[10px]">{item?.name}</p>{" "}
                              </div>
                            );
                          })}
                        </div>
                        <p className="text-xs text-neutral-400">
                          {exam?.exam?.description}
                        </p>
                        <button
                          onClick={() => redirectToExam(exam)}
                          className="w-full px-4 py-2 text-xs text-white rounded-md bg-primary h-max"
                        >
                          Take Exam
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex items-center justify-center p-6 min-h-40 h-max bg-primary/5">
                <p>
                  No Exams Found for you. Please check back later for more exams
                </p>
              </div>
            )}
          </div>

          <div className="flex flex-col">
            <p className="mb-4 text-xl font-semibold">Ongoing Exams</p>
            {ongoingExams.length > 0 ? (
              <div className="grid gap-6 p-6 grid-cols1 lg:grid-cols-3 min-h-40 h-max bg-primary/5">
                {ongoingExams.map((exam) => {
                  return (
                    <div className="flex flex-col w-full h-full gap-6 p-3 bg-white rounded-lg sm:flex-row sm:items-center sm:justify-between min-h-20">
                      <div className="flex flex-col justify-between w-full h-full gap-4 capitalize">
                        <div>
                          <p className="font-semibold ">{exam?.exam?.name}</p>
                          <p className="text-sm text-neutral-400">
                            {exam?.exam?.category?.name}
                          </p>
                          <p className="text-xs text-neutral-400">
                            {/* {exam?.exam?.duration} min */}
                            {convertToMinandSec(exam?.exam?.duration)}
                          </p>
                        </div>
                        <div className="flex flex-wrap gap-2 text-primary sm:items-center sm:justify-start">
                          {exam?.exam?.category?.sub_categories?.map((item) => {
                            return (
                              <div className="flex items-center gap-2 p-2 rounded-full bg-neutral-100">
                                <p className="text-[10px]">{item?.name}</p>{" "}
                              </div>
                            );
                          })}
                        </div>
                        <p className="text-xs text-neutral-400">
                          {exam?.exam?.description}
                        </p>
                        <button
                          onClick={() => redirectToExam(exam)}
                          className="w-full px-4 py-2 text-xs text-white rounded-md bg-primary h-max"
                        >
                          Continue Exam
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex items-center justify-center p-6 min-h-40 h-max bg-primary/5">
                <p>
                  No Ongoing Exams Found for you. Please check back later for
                </p>
              </div>
            )}
          </div>

          {/* <div className="flex flex-col">
            <p className="mb-4 text-xl font-semibold">Completed Exams</p>

            {completedExams.length > 0 ? (
              <div className="grid gap-6 p-6 grid-cols1 lg:grid-cols-3 min-h-40 h-max bg-primary/5">
                {completedExams.map((exam) => {
                  return (
                    <div className="flex flex-col w-full h-full gap-6 p-3 bg-white rounded-lg sm:flex-row sm:items-center sm:justify-between min-h-20">
                      <div className="flex flex-col">
                        <p className="font-semibold ">{exam?.exam?.name}</p>
                        <p className="text-sm">{exam?.exam?.category?.name}</p>
                        <div className="flex flex-wrap gap-2 text-primary sm:items-center sm:justify-start">
                          {exam?.exam?.category?.sub_categories?.map((item) => {
                            return (
                              <div className="flex items-center gap-2">
                                <p className="text-xs">{item?.name}</p>{" "}
                                <div className="h-1.5 w-1.5 rounded-full bg-primary"></div>{" "}
                              </div>
                            );
                          })}
                       
                        </div>
                      </div>

                     
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex items-center justify-center p-6 min-h-40 h-max bg-primary/5">
                <p>
                  No Completed Exams Found for you. Please check back later for
                </p>
              </div>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ExamList;
