import { InMemoryCache, Observable } from '@apollo/client';
import ApolloClient from 'apollo-boost'
import { REFRESH_TOKEN } from './graphql/queries';




const getRefreshToken = async () => {
    try {
        const token = await sessionStorage.getItem("refreshToken");
        if (!token) {
            throw new Error("Refresh token not found");
        }
        const headers = {
            Authorization: `Bearer ${token}`,
        };
        const refreshResolverResponse = await client.mutate({
            mutation: REFRESH_TOKEN,
            context: {
                headers,
            },
        });
        const accessToken =
            refreshResolverResponse.data?.refreshToken.tokens?.access_token;
        const refreshToken =
            refreshResolverResponse.data?.refreshToken.tokens?.refresh_token;
        // localStorage.setItem("refreshToken", refreshToken);
        // localStorage.setItem("token", accessToken);
        sessionStorage.setItem("refreshToken", refreshToken);
        sessionStorage.setItem("token", accessToken);
        return accessToken;
    } catch (err) {
        // localStorage.removeItem("refreshToken");
        // localStorage.removeItem("token");
        throw err;
    }
};
const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_LINK,
    cache: new InMemoryCache(),
    onError: ({ graphQLErrors, networkError, operation, forward }) => {

        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                switch (err.extensions.code) {
                    case "invalid-jwt":
                        if (operation.operationName === "refreshToken") return;

                        const observable = new Observable((observer) => {
                            (async () => {
                                try {
                                    const accessToken = await getRefreshToken();

                                    if (!accessToken) {
                                        throw new Error("Access token not found");
                                    }

                                    const subscriber = {
                                        next: observer.next.bind(observer),
                                        error: observer.error.bind(observer),
                                        complete: observer.complete.bind(observer),
                                    };

                                    forward(operation).subscribe(subscriber);
                                } catch (err) {
                                    observer.error(err);
                                }
                            })();
                        });

                        return observable;
                    default:
                        break;
                }
            }
        }
    },
    request: (operation) => {
        if (operation.operationName === "refreshToken") return;
        if (operation.operationName !== "examLogin" && operation.operationName !== "verifyOTP")
            operation.setContext({
                headers: {
                    authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    'x-hasura-role': 'user'
                }
            })
    }

});

export default client