import React, { useState } from "react";
import Button from "../components/utils/Button";
import { examLogin, verifyOtp } from "../store/slices/exam";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const Auth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otpForm, setOtpForm] = useState(false);
  const [error, setError] = useState(null);
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    register: registerOtp,
    handleSubmit: handleSubmitOtp,
    formState: { errors: errorsOtp },
  } = useForm();
  const RequestOtp = async (data) => {
    try {
      const request = await dispatch(examLogin(`+251${data.phone_number}`));
      if (request.payload?.examLoginRequest?.message) {
        setOtpForm(true);
      } else {
        if (
          request?.error?.message ===
          "GraphQL error: CANT_FIND_USER_ACCESS_DENIED"
        ) {
          setError("User not found");
        } else {
          setError("Something went wrong");
        }
      }
    } catch (error) {
      setError("Something went wrong");
    }
  };

  const VerifyOtp = async (data) => {
    try {
      const request = await dispatch(
        verifyOtp({
          phone_number: `+251${getValues("phone_number")}`,
          otp: data.otp,
        })
      );
      // tokens
      if (request.payload?.verifyOTP?.tokens) {
        sessionStorage.setItem(
          "token",
          request.payload.verifyOTP.tokens.access_token
        );
        sessionStorage.setItem(
          "refreshToken",
          request.payload.verifyOTP.tokens.refresh_token
        );
        navigate("/exam-list");
      } else {
        if (request?.error?.message.includes("NOT_CORRECT")) {
          setError("Invalid OTP");
        } else if (request?.error?.message.includes("EXPIRED")) {
          setError("OTP Expired");
        } else {
          setError("Something went wrong");
        }
      }
    } catch (error) {
      setError("Something went wrong");
    }
  };

  return (
    <div className="layout py-6 min-h-[88vh] overflow-hidden h-max w-full relative flex flex-col justify-center items-center gap-6 font-primary z-20">
      {!otpForm ? (
        <div className="flex flex-col gap-4 max-w-[22rem] p-6 rounded-lg bg-primary/5">
          <div className="flex flex-col items-center justify-center gap-2 mb-8">
            <p className="text-2xl font-bold text-primary">Login To Exam</p>
            <p className="text-sm font-light text-center text-neutral-400">
              You can access your exams dashboard using your Phone Number.
            </p>
          </div>
          <form onSubmit={handleSubmit(RequestOtp)}>
            <div className="bg-white border-2 flex place-items-center gap-x-2 border-primary rounded-lg p-2.5 focus-within::border focus-within::outline-0 ">
              <span className="text-sm font-light">+251</span>
              <input
                className="w-full h-full focus:border-0 focus:outline-0 "
                placeholder="Enter Phone Number"
                {...register("phone_number", {
                  required: "Phone number is required",
                  pattern: {
                    value: /^9[0-9]{8}$/,
                    message: "Invalid phone number",
                  },
                })}
              />
            </div>
            <div className="flex flex-col items-end mt-1">
              {errors.phone_number && (
                <span className="text-xs text-red-500">
                  {errors.phone_number.message}
                </span>
              )}
              {error && <span className="text-xs text-red-500">{error}</span>}
            </div>

            <Button type="submit" custom={"w-full bg-primary text-white mt-2"}>
              Send OTP
            </Button>
          </form>
        </div>
      ) : (
        <div className="flex flex-col gap-4 max-w-[22rem] p-6 rounded-lg bg-primary/5">
          <div className="flex flex-col items-center justify-center gap-2 mb-8">
            <p className="text-2xl font-bold text-primary">OTP Number</p>
            <p className="text-sm font-light text-center text-neutral-400">
              You can access your exams dashboard using your E-LMIS Phone
              Number.
            </p>
          </div>
          <form onSubmit={handleSubmitOtp(VerifyOtp)}>
            <input
              className="bg-white border-2 w-full border-primary rounded-lg p-2.5 focus:border focus:outline-0 "
              placeholder="Enter OTP Number"
              {...registerOtp("otp", {
                required: "OTP is required",
                pattern: {
                  value: /^[0-9]{6}$/,
                  message: "Invalid OTP",
                },
              })}
            />
            <div className="flex items-center justify-between mt-1 mb-3">
              <button
                onClick={() => setOtpForm(false)}
                className="text-xs text-primary"
              >
                Change Phone Number
              </button>
              <button
                onClick={() =>
                  RequestOtp({ phone_number: getValues("phone_number") })
                }
                className="text-xs text-primary"
              >
                Resend OTP
              </button>
            </div>
            {errorsOtp.otp && (
              <span className="text-xs text-red-500">
                {errorsOtp.otp.message}
              </span>
            )}
            {error && <span className="text-xs text-red-500">{error}</span>}
            <Button type="submit" custom={"w-full mt-2 bg-primary text-white"}>
              Verify OTP
            </Button>
          </form>
        </div>
      )}
    </div>
  );
};

export default Auth;
