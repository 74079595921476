import React, { useEffect, useRef, useState } from "react";
import MultipleChoiceBar from "../components/utils/MultipleChoiceBar";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Button from "../components/utils/Button";
import {
  getNextQuestion,
  submitAnswer,
  getQuestionBack,
  finishExam,
  getResult,
  updateAvailableTime,
} from "../store/slices/exam";
import { useDispatch, useSelector } from "react-redux";
import { redirect, useNavigate } from "react-router-dom";
import useFullscreenStatus from "../Hooks/useFullscreenStatus";
import { MdOutlineStart } from "react-icons/md";

import Navbar from "../components/layout/Navbar";
import MaximizableView from "../Hooks/MaximizableView";

const TakeExam = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [time, setTime] = useState(0);
  const [sesstionTime, setSesstionTime] = useState(0);
  const [stattExamModal, setStartExamModal] = useState(true);
  const intervalRef = useRef(null);
  const sessionInterval = useRef(null);

  const { exam, nextQuestion, result, stoppedAt } = useSelector(
    (state) => state.exam
  );
  const [question, setQuestion] = useState(null);
  const [loading, setLoading] = useState(false);
  const [backLoading, setBackLoading] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [resultLoading, setResultLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (nextQuestion?.answers) {
      if (nextQuestion.answers.length > 0) {
        setQuestion(nextQuestion.answers[0].exam_question);
        if (nextQuestion.answers[0]?.choice_id) {
          setSelectedOption(nextQuestion.answers[0].choice_id);
        }
        if (exam?.exam?.duration_type === "Per_question") {
          setTime(nextQuestion.answers[0].exam_question.question.duration);
        }
      }
    }
    // eslint-disable-next-line
  }, [nextQuestion]);

  const handleOptionClick = (choice) => {
    setSelectedOption(choice.id);
  };

  const finishCurrentExam = async () => {
    try {
      const examTakerId = exam?.id;
      await dispatch(finishExam(examTakerId));
      setResultModal(true);
      setResultLoading(true);
      setTime(0);
      await dispatch(getResult(examTakerId));
      setResultLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (time <= 0) {
        clearInterval(intervalRef.current);
      }

      setTime((prevTime) => prevTime - 1);
    }, 1000);

    if (time <= 0) {
      clearInterval(intervalRef.current);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [time, exam]);

  // useEffect(() => {
  //   if (exam?.exam?.duration_type === "Per_exam") {
  //     sessionInterval.current = setInterval(() => {
  //       if (sesstionTime <= 0) {
  //         clearInterval(sessionInterval.current);
  //       }

  //       setSesstionTime((prevTime) => prevTime - 10);
  //       dispatch(
  //         updateAvailableTime({
  //           exam_id: exam?.id,
  //           available_time: sesstionTime,
  //         })
  //       );
  //     }, 10000);

  //     return () => {
  //       clearInterval(sessionInterval.current);
  //     };
  //   }
  //   if (sesstionTime <= 0) {
  //     clearInterval(sessionInterval.current);
  //   }
  // }, [sesstionTime, exam]);

  useEffect(() => {
    if (!exam) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [exam]);

  const fetchNextExamQuestion = async () => {
    const examTakerId = exam?.id;
    setSelectedOption(null);
    await dispatch(getNextQuestion(examTakerId));

    setLoading(false);
  };
  const submitAnswerAndFetchNextQuestion = async () => {
    setLoading(true);
    if (
      nextQuestion.answers[0] &&
      question?.order < exam?.exam?.total_questions
    ) {
      await dispatch(
        submitAnswer({
          answer_id: nextQuestion.answers[0].id,
          choice_id: selectedOption,
          time_left: time,
        })
      );
    }
    if (question?.order === exam?.exam?.total_questions) {
      finishCurrentExam();
    } else nextQuestionwithOrder();
  };
  const backQuestion = async () => {
    setBackLoading(true);
    const examTakerId = exam?.id;
    const order = Number(question?.order) - 1;
    const variable = {
      exam_taker_id: examTakerId,
      order: order,
    };
    await dispatch(getQuestionBack(variable));
    setBackLoading(false);
  };

  const nextQuestionwithOrder = async () => {
    setLoading(true);
    const examTakerId = exam?.id;
    const order = Number(question?.order) + 1;
    const variable = {
      exam_taker_id: examTakerId,
      order: order,
    };
    await dispatch(getQuestionBack(variable));
    setLoading(false);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const canBack = (order) => {
    return question?.order === 1
      ? false
      : exam?.exam?.duration_type === "Per_question"
      ? false
      : order <= Number(stoppedAt) + 1
      ? false
      : true;
  };

  const questionPercentage =
    (question?.order / exam?.exam?.total_questions) * 100;

  useEffect(() => {
    if (time === 0) {
      clearInterval(intervalRef.current);
    }
  }, [time]);

  const redirectToLMIS = () => {
    window.location.href = process.env.REACT_APP_LMIS_URL;
  };

  // const startExam = async () => {
  //   setIsFullscreen(true);
  //   if (exam?.id) {
  //     setIsFullscreen(true);
  //     setStartExamModal(false);
  //     if (!nextQuestion) {
  //       fetchNextExamQuestion();
  //       if (exam?.exam?.duration_type === "Per_exam") {
  //         setTime(
  //           exam?.available_time ? exam?.available_time : exam?.exam.duration
  //         );
  //         setSesstionTime(
  //           exam?.available_time ? exam?.available_time : exam?.exam.duration
  //         );
  //       }
  //     }
  //   } else {
  //     navigate("/");
  //   }
  // };

  useEffect(() => {
    if (exam?.id) {
      fetchNextExamQuestion();
      if (exam?.exam?.duration_type === "Per_exam") {
        setTime(
          exam?.available_time ? exam?.available_time : exam?.exam.duration
        );
        setSesstionTime(
          exam?.available_time ? exam?.available_time : exam?.exam.duration
        );
      }
    }
  }, [exam]);

  // const maximizableElement = React.useRef(null);
  // let isFullscreen, setIsFullscreen;
  // let errorMessage;

  // try {
  //   [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
  // } catch (e) {
  //   errorMessage = "Fullscreen not supported";
  //   isFullscreen = false;
  //   setIsFullscreen = undefined;
  // }

  // useEffect(() => {
  //   console.log(isFullscreen);
  //   if (!isFullscreen) {
  //     setStartExamModal(true);
  //   }
  // }, [isFullscreen]);

  return (
    <>
      <MaximizableView backgroundColor="#F5F5F5" exam={exam}>
        {nextQuestion ? (
          <div
            className={`flex maximizable-containe flex-col w-full gap-4 px-5 py-4 lg:px-8 `}
          >
            <div className="flex flex-col items-end w-full gap-6">
              <div className="w-full h-3 bg-[#F2F2F2] rounded-full relative">
                <div
                  style={{ width: `${questionPercentage}%` }}
                  className="h-full bg-[#32A583] rounded-full transition-all duration-300 ease-in-out"
                ></div>
              </div>
              <div className="flex flex-col items-end">
                {/* if per_exam < 60 sec if per_question < 10 sec */}
                <p
                  className={`text-2xl text-[#32A583] font-bold 
                  ${
                    exam?.exam?.duration_type === "Per_exam" && time < 60
                      ? "text-red-400"
                      : exam?.exam?.duration_type === "Per_question" &&
                        time < 10
                      ? "text-red-400"
                      : ""
                  }
                  
                  `}
                >
                  {formatTime(time)}
                </p>
                <p className="text-xs text-[#555555] font-semibold">
                  Time Left
                </p>
              </div>
            </div>
            {/* {sesstionTime} */}
            <p className="text-xl font-medium text-center text-black">
              Question {question?.order} of {exam?.exam?.total_questions}
            </p>

            <div className="flex flex-col w-full max-w-screen-xl mx-auto">
              <div className="flex flex-col items-center gap-2 select-none h-max question-card">
                {question && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: question.question.question,
                    }}
                  ></div>
                )}
              </div>

              {/* Options Container */}
              <div className="grid w-full grid-cols-1 gap-4 mt-10 lg:gap-10 lg:grid-cols-2">
                {question?.question?.choices.map((choice, index) => (
                  <MultipleChoiceBar
                    disabled={time <= 0}
                    key={index}
                    optionName={String.fromCharCode(65 + index)}
                    optionText={choice.choice_text}
                    isSelected={choice.id === selectedOption}
                    handleClick={() => handleOptionClick(choice)}
                  />
                ))}
              </div>
            </div>

            {/* Buttons */}
            <div className="flex items-center justify-end w-full max-w-screen-xl gap-4 mx-auto mt-10">
              {canBack(question?.order) && (
                <Button
                  disabled={backLoading || loading}
                  handleClick={backQuestion}
                  custom={`text-primary border-primary hover:bg-primary hover:text-white flex items-center gap-2 ${
                    loading || backLoading ? "opacity-85" : ""
                  }`}
                >
                  {/* {backLoading ? (
              <AiOutlineLoading3Quarters className="w-4 h-4 text-white animate-spin" />
            ) : null} */}
                  Back
                </Button>
              )}
              <Button
                handleClick={submitAnswerAndFetchNextQuestion}
                custom={`bg-primary text-white border-primary ${
                  loading || backLoading ? "opacity-85" : ""
                }`}
                disabled={loading || backLoading}
              >
                {/* {loading ? (
            <AiOutlineLoading3Quarters className="w-4 h-4 text-white animate-spin" />
          ) : null} */}
                {question?.order === exam?.exam?.total_questions
                  ? "Finish Exam"
                  : "Next Question"}
              </Button>
            </div>

            {resultModal && (
              <div className="fixed top-0 left-0 right-0 z-50 flex flex-col items-center justify-center w-screen h-screen bg-black/5 backdrop-blur-sm">
                <div className="relative w-full max-h-full overflow-auto bg-white rounded-lg shadow lg:w-3/5 ">
                  <div className="w-full h-full p-8 result-modal">
                    <div className="flex flex-col rounded-t md:p-5">
                      <h3 className="text-xl lg:text-2xl font-semibold text-[#555555]">
                        Result for Exam {exam?.exam?.name}
                      </h3>
                      <p className="text-sm lg:text-base">
                        {exam?.exam?.descrition}
                      </p>
                    </div>
                    {!resultLoading ? (
                      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                        {result?.results.map((result, index) => {
                          return (
                            <div
                              key={index}
                              className="flex flex-col gap-y-2 p-5 border border-[#E1E1E1] rounded-3xl"
                            >
                              <span className="text-[#575757] text-xl lg:text-2xl font-semibold">
                                {result?.sub_category?.name}
                              </span>
                              <span className="text-sm text-[#696969] ">
                                {result?.result_text?.text}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="flex items-center justify-center w-full h-full">
                        <AiOutlineLoading3Quarters className="w-10 h-10 text-primary animate-spin" />
                      </div>
                    )}
                    <div className="flex items-center justify-end gap-4 py-4">
                      <Button
                        handleClick={() => navigate("/exam-list")}
                        custom="bg-primary text-white border-primary"
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className="flex items-center justify-center w-full h-full">
              <AiOutlineLoading3Quarters className="w-10 h-10 text-primary animate-spin" />
            </div>
          </div>
        )}
      </MaximizableView>
    </>
  );
};

export default TakeExam;
