import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ApolloProvider } from '@apollo/client';
import client from "./apolloClient";
import { Provider } from 'react-redux';
import store from './store/rootSlice'
import * as Sentry from "@sentry/react";
Sentry.init({
  dsn: "https://ad0b0055e1eaec480b2eb880db3054ab@o4507186628263936.ingest.us.sentry.io/4507186630557696",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/exams\.lmis\.gov\.et\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);
