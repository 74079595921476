import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../apolloClient';
import { GET_EXAM_TAKER, GET_NEXT_QUESTION, START_EXAM, SUBMIT_ANSWER, GET_QUESTION_BACK, FINISH_EXAM, GET_RESULT, FACE_IDENTIFY, UPDATE_AVAILABLE_TIME, GetExamsForMe, EXAM_LOGIN, VERIFY_OTP } from '../../graphql/queries';

export const getExam = createAsyncThunk('exam/getExamm', async (exam_taker_id) => {
    try {
        const { data } = await client.query({
            query: GET_EXAM_TAKER,
            fetchPolicy: "network-only",
            variables: {
                exam_taker_id: exam_taker_id,
            },
        });
        return data;
    } catch (error) {
        throw error;
    }
});

export const getNextQuestion = createAsyncThunk('exam/nextQuestion', async (exam_taker_id,
    { getState }
) => {
    let nextQuestion = null
    try {
        const { data } = await client.query({
            fetchPolicy: "network-only",
            query: GET_NEXT_QUESTION,
            variables: {
                exam_taker_id: exam_taker_id,
            },
        });
        if (
            getState().exam.exam?.started_at !== null &&
            data?.psychometric_test?.exam_taker_by_pk?.answers[0].exam_question?.exam.total_questions !== data?.psychometric_test?.exam_taker_by_pk?.answers[0].exam_question?.order
        ) {
            const examTakerId = exam_taker_id;
            await client.mutate({
                mutation: SUBMIT_ANSWER,
                variables: {
                    answer_id: data?.psychometric_test?.exam_taker_by_pk?.answers[0].id,
                    choice_id: null,
                    time_left: data?.psychometric_test?.exam_taker_by_pk?.available_time > 0
                        ? data?.psychometric_test?.exam_taker_by_pk?.available_time :
                        data?.psychometric_test?.exam_taker_by_pk?.answers[0].exam_question?.exam.duration,
                },
            });
            const { data: SecondData } = await client.query({
                fetchPolicy: "network-only",
                query: GET_NEXT_QUESTION,
                variables: {
                    exam_taker_id: examTakerId,
                },
            });
            nextQuestion = SecondData;
        }
        return nextQuestion || data;
    } catch (error) {
        throw error;
    }
}
);

export const getQuestionBack = createAsyncThunk('exam/getQuestionBack', async (variable) => {
    try {
        const { data } = await client.query({
            fetchPolicy: "network-only",
            query: GET_QUESTION_BACK,
            variables: {
                exam_taker_id: variable.exam_taker_id,
                order: variable.order
            },
        });
        return data;

    } catch (error) {
        throw error;
    }
}
);

export const updateAvailableTime = createAsyncThunk('exam/updateAvailableTime', async (payload) => {
    try {
        const { data } = await client.mutate({
            mutation: UPDATE_AVAILABLE_TIME,
            variables: {
                exam_id: payload.exam_id,
                available_time: payload.available_time
            },
        });
        return data;
    } catch (error) {
        throw error;

    }
}
);


export const faceIdentify = createAsyncThunk('exam/faceIdentify', async (image) => {
    try {
        const { data } = await client.mutate({
            mutation: FACE_IDENTIFY,
            variables: {
                photo: image,
            },
        });
        return data;
    } catch (error) {
        throw error;
    }
}
);


export const submitAnswer = createAsyncThunk('exam/submitAnswer', async (answer) => {
    try {
        const { data } = await client.mutate({
            mutation: SUBMIT_ANSWER,
            variables: {
                answer_id: answer.answer_id,
                choice_id: answer.choice_id,
                time_left: answer.time_left,
            },
        });
        return data;
    } catch (error) {
        throw error;
    }
}
);


export const startExam = createAsyncThunk('exam/startExam', async (exam_taker_id) => {
    try {
        const { data } = await client.mutate({
            mutation: START_EXAM,
            variables: {
                exam_taker_id: exam_taker_id,
            },
        });
        return data;
    } catch (error) {
        throw error;
    }
}
);

export const finishExam = createAsyncThunk('exam/finishExam', async (exam_taker_id) => {
    try {
        const { data } = await client.mutate({
            mutation: FINISH_EXAM,
            variables: {
                exam_taker_id: exam_taker_id,
            },
        });
        return data;
    } catch (error) {
        throw error;
    }
}
);

// GET_RESULT

export const getResult = createAsyncThunk('exam/getResult', async (exam_taker_id) => {
    try {
        const { data } = await client.query({
            query: GET_RESULT,
            variables: {
                examTakerId: exam_taker_id,
            },
        });
        return data;
    } catch (error) {
        throw error;
    }
}
);

// GetExamsForMe
export const getExamsForMe = createAsyncThunk('exam/getExamsForMe', async () => {
    try {
        const { data } = await client.query({
            query: GetExamsForMe,
        });
        return data;
    } catch (error) {
        throw error;
    }
}
);

// EXAM_LOGIN
export const examLogin = createAsyncThunk('exam/examLogin', async (phone_number) => {
    try {
        const { data } = await client.mutate({
            mutation: EXAM_LOGIN,
            variables: {
                phone_number: phone_number,
            }

        });
        return data;
    } catch (error) {
        throw error;
    }
}
);

// /VERIFY_OTP
export const verifyOtp = createAsyncThunk('exam/verifyOtp', async (input) => {
    try {
        const { data } = await client.mutate({
            mutation: VERIFY_OTP,
            variables: {
                phone_number: input.phone_number,
                code: input.otp,
            }
        });
        return data;
    } catch (error) {
        throw error;
    }
}
);


const examSlice = createSlice({
    name: 'exam',
    initialState: {
        exam: null,
        nextQuestion: null,
        status: null,
        loading: false,
        error: null,
        result: {},
        stoppedAt: 0,
        examsForMe: [],
        examError: null,
        otpRequested: false,
    },
    reducers: {
        setStoppedAt: (state, action) => {
            state.stoppedAt = action.payload;
        },
        setExam: (state, action) => {
            state.exam = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getExam.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(getExam.fulfilled, (state, { payload }) => {
            state.exam = payload?.psychometric_test?.exam_taker_by_pk;
            state.status = 'success';
        });
        builder.addCase(getExam.rejected, (state, { error }) => {
            state.status = 'failed';
            state.error = error.message;
        });

        builder.addCase(getNextQuestion.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(getNextQuestion.fulfilled, (state, { payload }) => {
            state.nextQuestion = payload?.psychometric_test?.exam_taker_by_pk;
            state.status = 'success';
        });
        builder.addCase(getNextQuestion.rejected, (state, { error }) => {
            state.status = 'failed';
            state.error = error.message;
        });

        builder.addCase(startExam.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(startExam.fulfilled, (state, { payload }) => {
            // state.exam = payload;
            state.status = 'success';
        });
        builder.addCase(startExam.rejected, (state, { error }) => {
            state.status = 'failed';
            state.error = error.message;
        });
        builder.addCase(getQuestionBack.pending, (state) => {
            state.status = 'loading';
        }
        );
        builder.addCase(getQuestionBack.fulfilled, (state, { payload }) => {
            state.nextQuestion = payload?.psychometric_test?.exam_taker_by_pk;
            state.status = 'success';
        });
        builder.addCase(getQuestionBack.rejected, (state, { error }) => {
            state.status = 'failed';
            state.error = error.message;
        });
        builder.addCase(finishExam.pending, (state, { payload }) => {
            state.status = "loading"
        })
        builder.addCase(finishExam.fulfilled, (state, { payload }) => {
            state.status = "success"
        })
        builder.addCase(finishExam.rejected, (state, { error }) => {
            state.status = 'failed';
            state.error = error.message;
        })
        builder.addCase(getResult.pending, (state, { payload }) => {
            state.status = "loading"
        })
        builder.addCase(getResult.fulfilled, (state, { payload }) => {
            state.result = payload?.psychometric_test?.exam_taker_by_pk;
            state.status = "success"
        })
        builder.addCase(getResult.rejected, (state, { error }) => {
            state.status = 'failed';
            state.error = error.message;
        })

        builder.addCase(getExamsForMe.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(getExamsForMe.fulfilled, (state, { payload }) => {
            state.examsForMe = payload?.psychometric_test;
            state.status = 'success';
        });
        builder.addCase(getExamsForMe.rejected, (state, { error }) => {
            state.status = 'failed';
            state.examError = error.message
            // window.location.href = "/auth";
        }
        );

        builder.addCase(examLogin.pending, (state) => {
            state.status = 'loading';
        });
        builder.addCase(examLogin.fulfilled, (state, { payload }) => {
            state.otpRequested = true;
            state.status = 'success';
        });
        builder.addCase(examLogin.rejected, (state, { error }) => {
            state.status = 'failed';
            state.error = error.message;
        });



    }
});
export const { setStoppedAt, setExam } = examSlice.actions;
export default examSlice.reducer;


