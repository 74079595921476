import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import FaceScan from "./pages/FaceScan.jsx";
import Home from "./pages/Home.jsx";
import TakeExam from "./pages/TakeExam.jsx";
import InstructionPage from "./pages/InstructionPage.jsx";
import PsycExam from "./pages/PsycExam.jsx";
import Auth from "./pages/Auth.jsx";
import ExamList from "./pages/ExamList.jsx";

function App() {
  return (
    <>
      <Navbar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/face-scan" element={<FaceScan />} />
          <Route path="/take-exam" element={<TakeExam />} />
          <Route path="/instruction" element={<InstructionPage />} />
          {/* PsycExam */}
          <Route path="/practice-test" element={<PsycExam />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/exam-list" element={<ExamList />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
