import React, { useEffect, useRef, useState } from "react";
import Button from "../components/utils/Button";
import gsap from "gsap";
import HeroAnimation from "../components/layout/HeroAnimation";
import { useNavigate, useLocation } from "react-router-dom";
import client from "../apolloClient";
import { REFRESH_TOKEN } from "../graphql/queries";
import { getExam } from "../store/slices/exam";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import ContinueExamModal from "../components/layout/modals/ContinueExamModal";
import { setStoppedAt } from "../store/slices/exam";

const Home = () => {
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [examStarted, setExamStarted] = useState(false);
  const { exam } = useSelector((state) => state.exam);

  // check if the exam has started and examstarted is false
  useEffect(() => {
    if (exam && exam?.started_at !== null && !examStarted) {
      setExamStarted(true);
      dispatch(setStoppedAt(exam?.answers_aggregate?.aggregate?.count));
    }
  }, [exam]);

  useEffect(() => {
    fetchData();
  }, []);
  async function getRefreshToken() {
    const refreshToken = await sessionStorage.getItem("refreshToken");
    const headers = {
      Authorization: `Bearer ${refreshToken}`,
    };
    try {
      const refreshResolverResponse = await client.mutate({
        mutation: REFRESH_TOKEN,
        context: {
          headers,
        },
      });
      const accessToken =
        refreshResolverResponse.data?.refreshToken.tokens?.access_token;
      const refreshToken =
        refreshResolverResponse.data?.refreshToken.tokens?.refresh_token;

      await sessionStorage.setItem("refreshToken", refreshToken);
      await sessionStorage.setItem("token", accessToken);
      return accessToken;
    } catch (err) {
      gsap.killTweensOf("*");
      navigate("/auth");
      setError("Unauthorized");
    }
  }

  async function fetchExams(exam_id) {
    try {
      await dispatch(getExam(exam_id));
      setLoading(false);
    } catch (error) {
      setError("Unable to load exam");
      setLoading(false);
    }
  }
  const fetchData = async () => {
    const urlParams = new URLSearchParams(location.search);
    if (urlParams.get("token"))
      sessionStorage.setItem("refreshToken", urlParams.get("token"));
    if (urlParams.get("exam"))
      sessionStorage.setItem("exam_id", urlParams.get("exam"));
    const token =
      urlParams.get("token") || sessionStorage.getItem("refreshToken");
    const exam_id = urlParams.get("exam") || sessionStorage.getItem("exam_id");
    if (!token) {
      setError("Unauthorized");
      gsap.killTweensOf("*");
      navigate("/auth");
      return;
    }
    // if (!exam_id) {
    //   setError("Exam not found");
    //   gsap.killTweensOf("*");
    //   navigate("/auth");
    //   return;
    // }

    urlParams.delete("token");
    // window.history.replaceState({}, document.title, location.pathname);
    setLoading(true);
    if (token && !exam_id) {
      getRefreshToken().then(() => {
        setLoading(false);
        navigate("/exam-list");
      });
    }
    if (token && exam_id) {
      getRefreshToken().then(() => fetchExams(exam_id));
    }
    // getRefreshToken().then(() => fetchExams(exam_id));
  };

  useEffect(() => {
    animation();
  }, []);

  const redirectToExam = async () => {
    gsap.killTweensOf("*");
    navigate("/instruction");
  };
  const animation = () => {
    const tl = gsap.timeline({ repeat: -1 });
    if (!tl) return;
    tl.fromTo(
      containerRef.current.children[0],
      { y: "100%", opacity: 0 },
      {
        y: "0%",
        opacity: 1,
        duration: 1,
        ease: "power2.out",
      }
    )
      .to(containerRef.current.children[0], {
        opacity: 0,
        duration: 1,
        delay: 2,
        ease: "power2.in",
      })
      .fromTo(
        containerRef.current.children[1],
        { y: "100%", opacity: 1 },
        {
          y: "0%",
          opacity: 1,
          duration: 1,
          ease: "power2.out",
        }
      )
      .to(containerRef.current.children[1], {
        opacity: 0,
        duration: 1,
        delay: 2,
        ease: "power2.in",
      });

    return () => {
      tl.kill();
    };
  };

  return (
    <>
      <div className="layout py-6 min-h-[88vh] overflow-hidden h-max w-full relative flex flex-col justify-center items-center gap-6 font-primary z-20">
        <h1 className="text-4xl sm:text-5xl lg:text-6xl max-w-4xl sm:leading-normal lg:leading-[80px] text-center font-bold font-secondary text-black flex flex-col justify-center items-center capitalize">
          Now you have everything at your fingertips with our online
          examinations.
          <div
            className="h-10 sm:h-12 lg:h-16 w-[18rem] sm:w-[30rem] overflow-hidden capitalize mt-2 relative"
            ref={containerRef}
          >
            <p className="absolute top-0 max-w-4xl text-4xl font-bold text-center transform -translate-x-1/2 sm:text-5xl lg:text-6xl w-max font-secondary text-secondary left-1/2">
              To Get Certified
            </p>
            <p className="absolute top-0 max-w-4xl text-4xl font-bold text-center transform -translate-x-1/2 sm:text-5xl lg:text-6xl w-max font-secondary text-secondary left-1/2">
              to secure a job
            </p>
          </div>
        </h1>

        <p className="max-w-xl text-xs font-light text-center sm:text-sm lg:text-base">
          Archive the right path for your career and education through our
          online examination platform. easy to use, transparent, fully
          automated, and helps to get hire and to have a certificate. An Online
          Examination System For Secure And Seamless Online Exams
        </p>
        {error ? <div className="text-sm text-red-600">{error}</div> : null}
        {loading ? (
          <AiOutlineLoading3Quarters className="w-10 h-10 text-primary animate-spin" />
        ) : null}
        {!loading && !error ? (
          <Button
            handleClick={redirectToExam}
            custom={"bg-primary text-white border-primary"}
          >
            <div className="flex items-center justify-center gap-x-2">
              Start the Exam
              <svg width="14" height="13" viewBox="0 0 14 13" fill="none">
                <path
                  d="M0 6.4H12M12 6.4L6.6 1M12 6.4L6.6 11.8"
                  stroke="white"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </Button>
        ) : null}
      </div>

      <HeroAnimation />
      {examStarted && (
        <ContinueExamModal
          continueExam={redirectToExam}
          totalQuestions={exam?.exam?.total_questions}
          answeredQuestions={exam?.answers_aggregate?.aggregate?.count}
        />
      )}
    </>
  );
};

export default Home;
